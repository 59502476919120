@import "../../index.scss";
.header {
  background: var(--theme-primary-color);
  padding: $spacer;
  .second-row {
    cursor: pointer;
    display: flex;
    flex-direction: row;
  }
}
