@import "../../index.scss";

.map-container {
  height: 250px;
  transform: translateZ(-100px);
}

.error-color {
  color: var(--theme-color-error);
}

%shared-pin-style {
  width: 30px;
  background: var(--theme-primary-color);
  height: 30px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 15px);
}

%shared-pulse-after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #89849b;
}

.location-overview {
  .location-overview-selection {
    background: white;

    .location-selection-textfield {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      display: flex;
    }
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: $spacer $spacer * 2;

    box-sizing: border-box;
  }
  transform: translateZ(-100px);
}

%shared-pulse-style {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: calc(50% + 10px);
  top: calc(50% - 20px);
  margin: 31px 0px 0px -35px;
  transform: rotateX(55deg) rotateY(38deg);
  z-index: 0;
}

.pin {
  @extend %shared-pin-style;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pin-no-animation {
  @extend %shared-pin-style;
  transform: translateY(0) rotate(-45deg) !important; //need to overwrite MAPBOX not my fault
}

.pulse {
  @extend %shared-pulse-style;
  &:after {
    @extend %shared-pulse-after;
    animation-delay: 1.1s;
  }
}

.pulse-no-delay {
  @extend %shared-pulse-style;
  &:after {
    @extend %shared-pulse-after;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
