/**
 * Color System
 * ============
 *
 * Primarily use the functional color variables at the bottom rather than base palettes.
 *
 * A base color has light and dark variants. For example, `-l1` is one shade
 * lighter than the base, `-l2` is two shades lighter. The same applies to dark
 * variants, which are `-d1`, `-d2`, and so on.
**/

/* Base: Neutrals */
$color-neutral-1: #ffffff;
$color-neutral-2: #fafcfc;
$color-neutral-3: #e9eff0;
$color-neutral-4: #c7d1d5;
$color-neutral-5: #9ca9b1;
$color-neutral-6: #717e89;
$color-neutral-7: #4e5864;
$color-neutral-8: #363d49;
$color-neutral-9: #282e38;
$color-neutral-10: #222630;

/* Base: Brand */
$color-brand: #50c0c9;
$color-brand-l1: #59d2dc;
$color-brand-d1: #3e99a8;

$color-brand-ebs: #2d7596;
$color-brand-ebs-l1: #3891ba;
$color-brand-ebs-d1: #195a78;

/* Base: Functional Colors */
$color-warning: #ec4a3b;
$color-warning-l1: #ff6759;
$color-warning-d1: #c22b1d;

$color-success: #6fb23e;
$color-success-l1: #86c955;
$color-success-d1: #589929;

$color-warning: #f2a711;
$color-warning-l1: #ffba30;
$color-warning-d1: #d68f00;

/**
 * Functional Definitions 
 * ======================
**/

/* Layout colors */
$color-border: $color-neutral-4;

$color-page-bg: $color-neutral-3;
$color-page-fg: $color-neutral-1;

/* Typography colors */
$color-body-text: $color-neutral-8;

$color-link-text: $color-brand;
$color-link-text-hover: $color-brand-l1;
$color-link-text-active: $color-brand-d1;

/* Button: primary */
$color-button-primary-bg: $color-brand;
$color-button-primary-fg: $color-neutral-1;

$color-button-primary-bg-hover: $color-brand-l1;
$color-button-primary-fg-hover: $color-neutral-1;

$color-button-primary-bg-active: $color-brand-d1;
$color-button-primary-fg-active: $color-neutral-1;

$color-button-primary-bg-disabled: $color-neutral-4;
$color-button-primary-fg-disabled: $color-neutral-1;

/* Button: secondary */
$color-button-secondary-bg: transparent;
$color-button-secondary-border: $color-brand;
$color-button-secondary-fg: $color-brand;

$color-button-secondary-bg-hover: $color-neutral-2;
$color-button-secondary-border-hover: $color-brand-l1;
$color-button-secondary-fg-hover: $color-brand-l1;

$color-button-secondary-bg-active: $color-neutral-3;
$color-button-secondary-border-active: $color-brand;
$color-button-secondary-fg-active: $color-brand-d1;

$color-button-secondary-bg-disabled: $color-neutral-2;
$color-button-secondary-border-disabled: $color-neutral-4;
$color-button-secondary-fg-disabled: $color-neutral-5;
