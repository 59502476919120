@import "../../index.scss";

.page-container {
  min-width: 320px;
  .lds-ellipsis div {
    background: var(--theme-primary-color);
  }
  .page-header {
    .card-container {
      margin-bottom: 0px;

      .card-header-container {
        background: $color-brand-l1;
        border: none;
      }
    }
  }
}
