@import "../../index.scss";

.accordion-content-container {
  padding: $spacer * 2;
  .accordion-information:not(:last-child) {
    padding-bottom: $spacer * 2;
  }
}

.p-accordion-header > a {
  background-color: #c79000;
  color: #ffffff;
}

.claims-results-container {
  .mustard {
    .information-section {
      outline-color: $color-neutral-1;
      background-color: #c79000;
      .placeholder {
        color: $color-neutral-1;
      }
      .arrow {
        .before,
        .after {
          background: $color-neutral-1;
        }
      }
    }
  }
  .accordion-photos {
    .title {
      display: flex;
      font-size: $fs-3;
      margin-bottom: $spacer;
      color: $color-neutral-8;
    }
    display: flex;
    flex-direction: column;

    .photos {
      width: 200px;

      overflow: auto;
    }
  }

  color: var(--theme-primary-color);
  .card-container {
    display: flex;
    justify-content: center;
    .card-container-content {
      .details-empty-state {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.claims-details-container {
  .data-table-container {
    tbody {
      &:after,
      &:before {
        border: none;
        content: "";
        display: block;
        height: $spacer;
      }
      .tabular-1 {
        min-width: 100px;
        padding: $spacer $spacer $spacer 0;
      }

      td:nth-child(2) {
        color: $color-neutral-8;
      }
      th,
      td {
        font-size: 14px;

        border-bottom: none;
      }
      .empty-cell {
        width: 16px;
      }
    }
  }
  .header-secondary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: $fs-1;
    letter-spacing: 0.04em;
    height: $spacer * 4;
    padding-left: $spacer * 2;
    color: $color-neutral-1;
    background: $color-brand;
    text-transform: uppercase;
  }

  .accordion-single-block {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: start;
    margin-bottom: 10px;
  }

  .accordion-single-block .title{
    width: 200px;
    font-weight: 500;
  }

  .accordion-container {
    .placeholder {
      text-transform: uppercase;
    }
    .options-container {
      .accordion-information {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .accordion-information-sub {
        .title {
          padding-bottom: $spacer;
          font-weight: bold;
        }
        .sub-value {
          .title {
            padding-bottom: 0;
          }
          .accordion-sub-information {
            display: flex;
            width: 100%;
          }

          .accordion-sub-information:not(:last-child) {
            display: flex;
            flex: 0 0 auto;
            padding-bottom: $spacer;
          }
        }

        flex-direction: column;
        align-items: flex-start;
        .sub-value {
          padding-left: $spacer * 2;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .header-title {
      text-transform: capitalize;
      color: $color-neutral-8;
      font-weight: $fw-emphasis;
    }

    .accordion-information {
      .title {
        font-size: $fs-3;
        max-width: 100px;
        min-width: 100px;
        width: 100%;
        padding-right: $spacer * 2;
        color: $color-neutral-8;
        text-transform: capitalize;
      }
      .value {
        font-size: $fs-3;
        color: $color-neutral-8;
        .empty-value {
          color: $color-neutral-6;
        }
      }
    }
  }

  .partial {
    .icon {
      background: url("/assets/icon-ellipse.svg") no-repeat center center;
    }
  }
  .icon {
    height: 20px;
    width: 20px;
    left: calc(100% - 60px);
    position: absolute;
  }

  .complete {
    .icon {
      background: url("/assets/icon-complete-checkmark.svg") no-repeat
        center center;
    }
  }

  .skipped {
    .information-section {
      .placeholder {
        color: $color-neutral-5;
      }
    }
  }

  .not-completed {
    background: #c79000;
    color: #fff;
  }
}

.sub-title {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  font-size: $fs-3;
  max-width: 100px;
  min-width: 100px;
  width: 100%;
  padding-right: $spacer * 2;
  color: $color-neutral-8;
  text-transform: capitalize;
}

.accordion-container .slide-down {
  max-height: 2000px;
}
