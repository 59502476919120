@import "../../../index.scss";

.telephone-search-container {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-bottom: $spacer;

  .telephone-input {
    width: 100%;
  }

  .search-button {
    &:hover {
      background: $color-neutral-1;
      .magnifying-glass {
        border-color: var(--theme-primary-color);
        &::before {
          background: var(--theme-primary-color);
        }
      }
    }
    outline: none;
    padding: $spacer * 2 $spacer * 3;
    margin-left: $spacer;
    background: var(--theme-primary-color-l1);
    border: 1px solid #373d48;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
  }
  .magnifying-glass {
    font-size: 20px;

    display: inline-block;
    width: 0.4em;
    height: 0.4em;
    border: 0.1em solid $color-neutral-1;
    position: relative;
    border-radius: 0.35em;
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      right: -0.25em;
      bottom: -0.1em;
      border-width: 0;
      background: $color-neutral-1;
      width: 0.35em;
      height: 0.08em;
      transform: rotate(45deg);
    }
  }
}
