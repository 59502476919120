@import "../../../index.scss";
.action-buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  flex-direction: row;
  .complete {
    background: #fff;
    color: var(--theme-primary-color);
    cursor: not-allowed;
  }

  button:first-child {
    min-width: 100px;
    margin-right: $spacer;
  }

  button {
    cursor: pointer;
    min-width: 200px;
    &:hover {
      background: #fff;
      color: var(--theme-primary-color);
    }
    outline: none;
    background: var(--theme-primary-color-l1);
    padding: $spacer;
    font-size: $fs-1;
    border: 1px solid #373d48;
    border-radius: 2px;
    color: #fff;
    &:disabled {
      pointer-events: none;
    }
  }
}

.action-buttons-container[disabled] {
  cursor: not-allowed;
}

.tracking-out-contract-bck {
  -webkit-animation: tracking-out-contract-bck 0.8s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: tracking-out-contract-bck 0.8s
    cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes tracking-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
    transform: translateZ(-500px);
    opacity: 0;
  }
}
@keyframes tracking-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
    transform: translateZ(-500px);
    opacity: 0;
  }
}

// Define vars we'll be using
$brand-success: var(--theme-primary-color);
$loader-size: 7em;
$check-height: 20px;
$check-width: 10px;
$check-left: ($loader-size/6 + $loader-size/12);
$check-thickness: 3px;
$check-color: $brand-success;

.show {
  display: block;
}
.hide {
  display: none;
}

.ars-link-button {
  position: relative;
}

.checkmark {
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    animation-delay: 0.7s;
    transform: scaleX(-1) rotate(135deg);
    opacity: 0;
    animation-fill-mode: forwards;
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: "";
    left: 38px;
    top: 18px;
    position: absolute;
  }
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 0.2;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 0.4;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
