@import "../../index.scss";
.sent {
  background: url("/assets/icon-complete-checkmark.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
}

.icon-x {
  background: url("/assets/icon-x.svg") no-repeat center center;
  width: 20px;
  height: 20px;
}

.search-results-container {
  .card-container {
    .data-table-container {
      table {
        tbody {
          tr:first-child {
            th {
              background: $color-neutral-3;
            }
          }
        }
      }
    }
    display: flex;
    justify-content: center;
    .overview-empty-state {
      font-size: 32px;
      padding: 42px;
      color: var(--theme-primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .claim-id-router-link {
      font-size: 14px;
      color: var(--theme-primary-color);
    }
  }
}
