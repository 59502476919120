/**
 * Type System
 * ===========
 *
 * This type hierarchy is based on [google material design](https://material.io/design/typography/the-type-system.html#type-scale)
 *
 * H1
 * H2
 * H3
 * H4
 * H5
 * H6
 * Subtitle 1
 * Subtitle 2
 * Body 1 (FS-3): Primary style for larger blocks of paragraph text
 * Body 2
 * Button
 * Caption
 * Overline: Form input labels
**/

/* Type scale */
$fs-1: $base-font-size * 0.625; /* 10 */
$fs-2: $base-font-size * 0.8125; /* 13 */
$fs-3: $base-font-size * 0.875; /* 14 */
$fs-4: $base-font-size * 1; /* 16 */
$fs-5: $base-font-size * 1.25; /* 20 */
$fs-6: $base-font-size * 1.5; /* 24 */
$fs-7: $base-font-size * 2;
$fs-8: $base-font-size * 3;
$fs-9: $base-font-size * 4;
$fs-10: $base-font-size * 6;

/* Font weight scale */
$fw-light: 300;
$fw-normal: 400;
$fw-emphasis: 600;

/* Font stack */
$font-stack-body: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", "Hiragino Sans",
  "Hiragino Kaku Gothic Pro", "游ゴシック", "游ゴシック体", YuGothic,
  "Yu Gothic", "ＭＳ ゴシック", "MS Gothic", sans-serif;
$font-stack-heading: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", "Hiragino Sans",
  "Hiragino Kaku Gothic Pro", "游ゴシック", "游ゴシック体", YuGothic,
  "Yu Gothic", "ＭＳ ゴシック", "MS Gothic", sans-serif;
$font-stack-mono: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
  monospace;

/* MAIN DEFINITIONS */
h1,
.h1 {
  font-size: $fs-10;
  line-height: $spacer * 14;
  font-family: $font-stack-heading;
  letter-spacing: -0.015em;
}

h2,
.h2 {
  font-size: $fs-9;
  line-height: $spacer * 9;
  font-family: $font-stack-heading;
}

h3,
.h3 {
  font-size: $fs-8;
  line-height: $spacer * 7;
  font-family: $font-stack-heading;
}

h4,
.h4 {
  font-size: $fs-7;
  line-height: $spacer * 5;
  font-family: $font-stack-heading;
}

h5,
.h5 {
  font-size: $fs-6;
  line-height: $spacer * 4;
  font-family: $font-stack-heading;
}

h6,
.h6 {
  font-size: $fs-5;
  line-height: $spacer * 3;
  letter-spacing: -0.01em;
  font-family: $font-stack-heading;
}

/* Use for normal size body copy */
.body-1,
p {
  font-size: $fs-4;
  line-height: $spacer * 3;
  font-family: $font-stack-body;
  margin-bottom: $spacer * 2;
  font-weight: $fw-normal;
}

p strong,
p b,
.body-1 strong,
.body-1 b {
  font-weight: $fw-emphasis;
}

code,
pre,
.body-1 code,
.body-1 pre {
  font-family: $font-stack-mono;
}

/* Used for smaller text, labels, etc. Better for single lines. Use .body-2-multiline for paragraphs */
.body-2,
figcaption,
.caption,
small {
  font-size: $fs-3;
  line-height: $spacer * 2;
  font-family: $font-stack-body;
  margin-bottom: $spacer * 2;
  font-weight: $fw-normal;
}

/* Use when 3+ lines of small text is required */
.body-2 p,
.body-2-multiline {
  line-height: $spacer * 2.5;
}

button,
.button {
  font-size: $fs-3;
  line-height: $spacer * 2;
  font-family: $font-stack-body;
  font-weight: $fw-emphasis;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.overline {
  font-size: $fs-1;
  line-height: $spacer * 2;
  font-family: $font-stack-body;
  font-weight: $fw-emphasis;
  letter-spacing: 0.05em;
}

label,
.label,
.float-label {
  font-size: $fs-1;
  line-height: $spacer * 2;
  font-family: $font-stack-body;
  font-weight: $fw-emphasis;
  letter-spacing: 0.03em;
}
