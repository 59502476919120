/* Global style configs */
@import "./global-styles/color.scss";
@import "./global-styles/spacing.scss";
@import "./global-styles/typography.scss";
@import "./global-styles/motion.scss";
@import "./global-styles/elevation.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, “Hiragino Sans”, “Hiragino Kaku Gothic Pro”, “游ゴシック“,
    “游ゴシック体“, YuGothic, “Yu Gothic”, “ＭＳ ゴシック“, “MS Gothic”;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  min-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
  overflow: auto;
  -webkit-tap-highlight-color: transparent;
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
