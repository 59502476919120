/* Base typographic units (referenced in typography.scss) */
$base-font-size: 16px;
$base-line-height: 1.5;

/* Spacer root */
$root-spacer: $base-font-size * $base-line-height;

/*
 * Common spacer units: USE THESE 
 * (If large space is required, use `$spacer * 10` to maintain the same line height ratio)
**/

$spacer:           $root-spacer / 3; /* Use when things should be close, like a label and its input */
$spacer-single:    $root-spacer * 1;
$spacer-double:    $root-spacer * 2;
$spacer-triple:    $root-spacer * 3;
$spacer-quadruple: $root-spacer * 4;
$spacer-quintuple: $root-spacer * 5;
