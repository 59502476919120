.language-toggle-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 50px;
  .selected {
    color: #fff;
  }
  span {
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}
