@import "../../index.scss";
.submit-error-container {
  display: flex;
  align-items: center;
  flex: 1;
  height: 56px;
  padding-left: $spacer;

  .error,
  .success {
    display: flex;
    align-items: center;
    color: #fff;
  }
  .icon-x-white {
    cursor: pointer;
    background: url("/assets/icon-x-white.svg") no-repeat center center;
    width: 20px;
    height: 20px;
  }
}
.submit-error-container.hide {
  height: 0;
  opacity: 0;
  transition: opacity 0.5s, height 1s;
}

.submit-error-container.show {
  height: 56px;
  opacity: 1;
  transition: opacity 0.5s, height 1s;
}

.submit-error-container.error {
  background-color: var(--theme-color-error);
}
.submit-error-container.success {
  background-color: var(--theme-color-success);
}
